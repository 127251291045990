/*colors*/
$color-scheme: (
    'orange': #FF8A00,
    'orange-light': #FFF1E0,
    'yellow': #FFBB3D,
    'yellow-light': #FFF2D9,
    'violet': #67399E,
    'violet-light': #F4E1FF,
    'blue': #0066FF,
    'blue-light': #D9F4FF,
    'green': #3C9D00,
    'green-light': #E3EDDD, 
    'error': #e20000,
    'error-color': rgba(255, 0, 0, 0.3),
    'gray-1': #707070,
    'gray-2': #999999,
    'gray-3': #B7B7B7,
    'gray-4': #EEEEEE,
    'gray-5': #F2F2F2,
    'gray-6': #F8F8F8,
    'gray-7': #F7F7F7,
    'white': #ffffff,
    'black': #000000,
);

$color-main: map-get($color-scheme, 'orange');
$color-primary: map-get($color-scheme, 'yellow'); 
$color-secondary: map-get($color-scheme, 'violet');
$color-neutral: map-get($color-scheme, 'gray-1');
$color-error: map-get($color-scheme, 'red');
$color-success: map-get($color-scheme, 'green');

// Z-index Order (From Hight To Low)
$z-indexes: (
    'toast',
    'modal',
    'header',
    'header-dropdowns',
    'header-menu',
    'sticky-element',
    'dropdown',
);

/* font */
$defaultHtmlFont: "Tahoma", sans-serif;
$fontSecondary: 'PlayfairDisplay';
$fontPrimary: 'Poppins'; 
$baseFontFamily: $fontPrimary;

/*grid dimensions*/
$mediaXxlLarge: 1366px;
$mediaXlLarge: 1280px;
$mediaXLarge: 1101px;
$mediaLarge: 1024px;
$mediaMedium: 768px;

/*font settings*/
$baseFontSize : 16;
 
/* grid settings */
$columnsSize: 12; //count of columns
$columnSpace: 16;

/*screenSizes*/
$media-xl: 1279px;
$media-lg: 1100px;
$media-md: 1023px;
$media-sm: 767px;
$media-xs: 450px;