@import "../utils/variables";

$fonts: (
  //  fontName    fontType   fontWeight  fontStyle
  $fontSecondary "ExtraBoldItalic" 800 italic,
  $fontSecondary "ExtraBold" 800 normal,
  $fontSecondary "Bold" bold normal,
  $fontSecondary "Regular" normal normal,
  $fontPrimary "ExtraBold" 800 normal,
  $fontPrimary "Bold" bold normal,
  $fontPrimary "SemiBold" 600 normal,
  $fontPrimary "Medium" 500 normal,
  $fontPrimary "Italic" normal italic,
  $fontPrimary "Regular" normal normal
);

@each $type in $fonts {
  @font-face {
    font-family: "#{nth($type, 1)}";
    src: local("#{nth($type, 1)} #{nth($type, 2)}"), local("#{nth($type, 1)}-#{nth($type, 2)}"),
      url("../../fonts/#{nth($type, 1)}-#{nth($type, 2)}.woff2") format("woff2"),
      url("../../fonts/#{nth($type, 1)}-#{nth($type, 2)}.woff") format("woff");
    font-weight: #{nth($type, 3)};
    font-style: #{nth($type, 4)};
    font-display: swap;
  }
}
