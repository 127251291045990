@import "../utils/_variables";

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-color: transparent;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
}

html {
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-size: 16px;

  @media (max-width: $media-xl) {
    font-size: 1.33vw;
  }
  @media (max-width: $media-lg) {
    font-size: 1.5625vw;
  }
  @media (max-width: $media-sm) {
    font-size: 16px;
  }
}

body {
  font-family: $baseFontFamily, $defaultHtmlFont;
  color: #000;

  &.locked {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    color: currentColor;
  }
}

img,
svg {
  display: inline-block;
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  height: auto;
}

// Input default style reset
input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration,
  &[type="number"] {
    -webkit-appearance: none;
  }
}

textarea {
  resize: vertical;
}
