.form-fields {
  &__item {
    margin-top: rem(24);

    &:first-child {
      margin-top: 0;
    }

    @media (max-width: $media-sm) {
      margin-top: rem(12);
    }
  }
}

.form-terms {
  margin-top: rem(16);
  
  a {
    text-decoration: underline;
    color: $color-main;
  }
}

.form-link{
  margin-top: rem(10);
  text-align: left;
}

.form-submit {
  margin-top: rem(40);

  .btn {
    width: 100%;
  }

  @media (max-width: $media-sm) {
    margin-top: rem(25);
  }
}

.ant-form-item-explain-error {
  margin-top: rem(6);
  padding-left: rem(5);
  text-align: left;
  @include font(normal, 14, 1, #e43d40, 0);
  @include font-media($media-sm, rem(12), 0);
}

.form-field {
  position: relative;
  margin: 0;

  .ant-form-item-label {
    margin-bottom: rem(8);
    padding: 0;

    .ant-form-item-required {
      &::before {
        display: none !important;
      }
    }
  }


  .ant-input-suffix,
  .ant-input-prefix {
    @include center-absolute(vertical);
    color: inherit;
  }

  &:not(.form-field_password) {
    .ant-input-suffix,
    .ant-input-prefix {
      @extend %not-selectable;
    }
  }

  &__element {
    background-color: initial;
    border: 0;
    padding: 0;
    color: inherit;
    font-size: inherit;
    line-height: initial;
    box-shadow: none;

    &:hover {
      border: 0;
      box-shadow: none;

      .ant-select-selector {
        box-shadow: none;
      }
    }

    &.ant-input,
    &.ant-input-affix-wrapper input,
    &:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      border-radius: rem(30);
      font-weight: 600;
      color: inherit;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        line-height: inherit;
        font-weight: inherit;
        font-size: inherit;
        font-family: inherit;
      }
    }
  }

  // SIZES *****************
  &_sm {
    .ant-input-suffix,
    .ant-input-prefix {
      font-size: rem(14);
    }

    .ant-input-affix-wrapper {
      .ant-input-suffix {
        right: rem(12);
      }
      .ant-input-prefix {
        left: rem(12);
      }

      input:last-child {
        padding-left: rem(12 + 24);
      }
      input:not(:last-child) {
        padding-right: rem(12 + 24);
      }
    }

    .form-field {
      &__element {
        &.ant-input,
        &.ant-input-affix-wrapper input {
          height: rem(32);
          padding: 0 rem(12);
          font-size: rem(12);
        }
      }
    }
  }

  &_md {
    .ant-input-suffix,
    .ant-input-prefix {
      font-size: rem(24);
    }

    .ant-input-affix-wrapper {
      .ant-input-suffix {
        right: rem(20);
      }
      .ant-input-prefix {
        left: rem(20);
      }

      input:last-child {
        padding-left: rem(20 + 24);
      }
      input:not(:last-child) {
        padding-right: rem(20 + 24);
      }
    }

    .form-field {
      &__element {
        &.ant-input,
        &.ant-input-affix-wrapper input,
        &:not(.ant-select-customize-input) .ant-select-selector {
          height: rem(48);
          padding: 0 rem(24);
          font-size: rem(16);
        }
      }
    }

    &.form-field_textarea {
      .form-field {
        &__element {
          &.ant-input,
          &.ant-input-affix-wrapper textarea {
            min-height: rem(230);
            padding: rem(24);
            line-height: 1.5;
            resize: none;

            @media (max-width: $media-lg) {
              min-height: rem(160);
              padding: rem(16) rem(16);
              border-radius: rem(24);
            }
            @media (max-width: $media-sm) {
              border-radius: rem(16);
            }
          }
        }
      }
    }
  }

  &_lg {
    .ant-form-item-label {
      margin-bottom: rem(16);

      @media (max-width: $media-lg) {
        margin-bottom: rem(4);
      }
    }

    .form-field {
      &__element {
        &.ant-input,
        &.ant-input-affix-wrapper input,
        &:not(.ant-select-customize-input) .ant-select-selector {
          height: rem(56);
          line-height: rem(52);
          padding: 0 rem(32);
          font-size: rem(16);
          font-family: $fontPrimary;

          .ant-select-selection-search {
            right: rem(32);
            left: rem(32);
            height: rem(30);
          }

          @media (max-width: $media-lg) {
            height: rem(48);
            line-height: rem(44);
            padding: 0 rem(24);

            .ant-select-selection-search {
              right: rem(24);
              left: rem(24);
            }
          }
          @media (max-width: $media-sm) {
            font-size: rem(14);
          }
        }

        &.custom-select_multiple {
          .ant-select-selector {
            height: auto;
            min-height: rem(56);
          }
        }

        .ant-select-arrow {
          font-size: rem(32);
          right: rem(14);
          color: initial;
        }
      }
    }

    .ant-input-affix-wrapper {
      .ant-input-suffix,
      .ant-input-prefix {
        font-size: rem(24);
      }

      .ant-input-suffix {
        right: rem(32);

        @media (max-width: $media-lg) {
          right: rem(20);
        }
      }
      .ant-input-prefix {
        left: rem(32);

        @media (max-width: $media-lg) {
          left: rem(20);
        }
      }

      input:last-child {
        padding-left: rem(32 + 24);

        @media (max-width: $media-lg) {
          padding-left: rem(20 + 24);
        }
      }
      input:not(:last-child) {
        padding-right: rem(32 + 24);

        @media (max-width: $media-lg) {
          padding-right: rem(20 + 24);
        }
      }
    }

    &.form-field_textarea {
      .form-field {
        &__element {
          &.ant-input,
          &.ant-input-affix-wrapper textarea {
            min-height: rem(230);
            padding: rem(32);
            line-height: 1.5;
            resize: none;

            @media (max-width: $media-lg) {
              min-height: rem(160);
              padding: rem(16) rem(24);
              border-radius: rem(24);
            }
            @media (max-width: $media-sm) {
              border-radius: rem(16);
            }
          }
        }
      }
    }
  }

  // TYPES ********************************
  &_neutral {
    .ant-input-suffix,
    .ant-input-prefix,
    .ant-select-arrow {
      color: map-get($color-scheme, "gray-3");
    }

    .form-field {
      &__element {
        &.ant-input,
        &.ant-input-affix-wrapper input {
          background-color: map-get($color-scheme, "gray-6");
        }
      }
    }

    &.form-field_search {
      .form-field__element {
        &.ant-input,
        &.ant-input-affix-wrapper input {
          @include input-placeholder {
            font-size: rem(16);
            font-weight: 600;
            color: map-get($color-scheme, "gray-3");
            font-family: $fontPrimary;
          }
        }
      }
    }
  }

  &_border {
    .ant-input-suffix,
    .ant-input-prefix {
      color: map-get($color-scheme, "gray-3");
    }

    .form-field {
      &__element {
        &.ant-input,
        &.ant-input-affix-wrapper input,
        &:not(.ant-select-customize-input) .ant-select-selector {
          background-color: #fff;
          border: rem(2) solid map-get($color-scheme, "gray-4");
          color: initial;

          @include input-placeholder {
            color: map-get($color-scheme, "gray-3");
          }
        }

        &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          box-shadow: none;
          border-color: map-get($color-scheme, "gray-4");
        }
      }
    }

    &.form-field_search {
      .form-field__element {
        &.ant-input,
        &.ant-input-affix-wrapper input {
          @include input-placeholder {
            font-size: rem(16);
            font-weight: 600;
            color: map-get($color-scheme, "gray-3");
            font-family: $fontPrimary;
          }
        }
      }
    }

    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper {
      &,
      &:hover {
        border: 0;
        background: initial;
        box-shadow: none;
      }

      &.ant-input,
      &.ant-input-affix-wrapper input,
      &:not(.ant-select-customize-input) .ant-select-selector {
        border-color: map-get($color-scheme, "error-color");
      }

      .ant-input-suffix,
      .ant-input-prefix {
        color: #e43d40;
      }
    }
  }
}

.tabs.ant-tabs {
  font-size: inherit;
  line-height: inherit;
  color: inherit;

  .ant-tabs-nav {
    margin-bottom: 0;

    &::before {
      display: none;
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab {
      padding: 0;
      font-size: inherit;

      &-active {
        .ant-tabs-tab-btn {
          color: inherit;
        }

        .tab__radio {
          background-color: $color-main;
          border-color: $color-main;

          &::after {
            @include scale(1);
          }
        }
      }
    }
  }
}

.ant-select-dropdown {
  line-height: initial;
  padding: rem(24) 0;
  box-shadow: 0 0.5rem 1rem rgba(#000, 0.15);
  font-size: initial;
  border-radius: rem(30);

  @media (max-width: $media-sm) {
    border-radius: rem(16);
    padding: 0;
  }

  &:not(.custom-select-dropdown_multiple) {
    .ant-select-item-option {
      line-height: rem(20);
      padding: 0 rem(40);
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;

      @media (max-width: $media-sm) {
        padding: 0 rem(24);
      }

      &-content {
        padding: rem(16) 0;
        @include font(600, 17, rem(26), inherit, 0);
        @include font-media($media-xl, rem(15), rem(22));
        @include font-media($media-sm, rem(14), rem(21));
        @include horizontal-decor("../../img/decor/horizontal.svg");

        @media (max-width: $media-sm) {
          padding: rem(12) 0;
        }

        &::after {
          top: 0;
        }
      }

      &:first-child {
        .ant-select-item-option-content::after {
          display: none;
        }
      }

      &-selected {
        background: $color-main;
        color: #fff;
      }

      &:focus:not(.ant-select-item-option-selected),
      &-active:not(.ant-select-item-option-selected) {
        background: map-get($color-scheme, "gray-5");

        .ant-select-item-option-content,
        & + .ant-select-item-option .ant-select-item-option-content {
          &::after {
            display: none;
          }
        }
      }
      &-selected {
        .ant-select-item-option-content,
        & + .ant-select-item-option .ant-select-item-option-content {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  &.modal-dropdown_sm {
    .ant-select-item-option {
      padding: 0 rem(16);
    }
  }
}

.filter-btn {
  display: inline-block;
  background-color: #fff;
  border-radius: rem(30);
  border: rem(2) solid map-get($color-scheme, "gray-4");
  padding: rem(14) rem(22);
  color: map-get($color-scheme, "gray-3");
  position: relative;
  font-size: inherit;
  font-weight: inherit;

  @media (max-width: $media-sm) {
    padding: rem(6) rem(11);
    border-width: rem(1);
  }

  &.ant-checkbox-wrapper::after,
  &.ant-radio-wrapper::after {
    display: none;
  }

  .ant-checkbox,
  .ant-radio {
    position: absolute;
    top: 0;
    left: 0;
    @extend %hidden;

    & + span {
      color: inherit;
      padding: 0;
    }
  }

  &_black {
    &.ant-checkbox-wrapper-checked,
    &.ant-radio-wrapper-checked {
      background-color: #000;
      color: #fff;
      border-color: #000;
    }
  }
  &_white {
    background-color: #fff;
    color: #000;
    border: 0;

    &.ant-checkbox-wrapper-checked,
    &.ant-radio-wrapper-checked {
      background-color: #000;
      color: #fff;
    }
  }
  &_main {
    &.ant-checkbox-wrapper-checked,
    &.ant-radio-wrapper-checked {
      background-color: $color-main;
      color: #000;
      border-color: $color-main;

      // @media (max-width: $media-sm) {
      //   border-color: #b7b7b7;
      // }
    }
  }
  &_border {
    background-color: #fff;
    color: #000;
    border: rem(2) solid map-get($color-scheme, "gray-4");

    &.ant-checkbox-wrapper-checked,
    &.ant-radio-wrapper-checked {
      background-color: $color-main;
      border-color: $color-main;
    }
  }

  &_typeable {
    &.filter-btn_main.ant-radio-wrapper-checked {
      background-color: transparent;
      border: 0;

      .filter-input {
        border-color: rgba($color: $color-main, $alpha: 0.5);
      }
    }
  }

  &.ant-radio-wrapper-disabled {
    cursor: default;
    opacity: 0.5;
    filter: blur(3px);

    .ant-radio-disabled + span {
      cursor: default;
    }
  }

  .filter-input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: 0;
  }
}

.ant-form .ant-radio-wrapper {
  font-size: inherit;
}

.ant-upload,
.ant-upload-list {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.ant-input,
.ant-time-picker-input,
.ant-input-number {
  color: map-get($color-scheme, "blue");

  &.transparent {
    background-color: transparent;
  }
}

.ant-input-affix-wrapper {
  .ant-input:not(:last-child) {
    padding-right: 40px;
  }
}

.ant-input:focus,
.ant-input:hover {
  border-color: map-get($color-scheme, "blue-light");
}

.ant-input:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(map-get($color-scheme, "blue-light"), 0.2);
  box-shadow: 0 0 0 2px rgba(map-get($color-scheme, "blue-light"), 0.2);
}

.ant-select {
  &.transparent {
    .ant-select-selection--single {
      background-color: transparent;
    }
  }
}

.ant-select-selection {
  background-color: inherit;
}

.ant-select {
  &.custom-selected {
    .ant-select-selection-selected-value {
      float: none;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.ant-select-selection-selected-value {
  color: map-get($color-scheme, "blue");
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-input-number {
  &.transparent {
    background-color: transparent;
  }
}

.ant-checkbox-group {
  &.list {
    label {
      display: flex;
      margin-right: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}

.ant-checkbox-wrapper.custom-checkbox {
  @extend %flex;
  @extend %align-center;
  text-align: left;

  .ant-checkbox {
    font-size: inherit;

    &::after {
      display: none;
    }

    &-inner {
      top: 0;
      width: rem(20);
      height: rem(20);
      background-color: transparent;
      border: rem(2) solid map-get($color-scheme, 'gray-3');
      border-radius: rem(5);

      @media (max-width: $media-sm) {
        top: rem(1); 
      }

      &::after {
        @include font-icon("\e909"); //checkmark
        @include translate(-50%, -50%);
        left: 50%;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        font-size: rem(16);
        color: #fff;
      }
    }

    & + span {
      font-size: rem(16);
      padding: 0 0 0 rem(10);
      @include font-media($media-sm, rem(14), 0);
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $color-main;
        border-color: $color-main;
      }
    }
  }
}

.ant-message-notice-content {
  padding: rem(10) rem(15);
  border-radius: rem(30);

  .ant-message-custom-content {
    font-size: rem(16);
    @extend %inline-flex;
    @extend %align-middle;

    &.ant-message-error {
      color: #e43d40;
    }
    &.ant-message-success {
      color: map-get($color-scheme, "green");
    }

    .anticon {
      top: initial;
      font-size: rem(16);
    }
  }
}

.ant-modal-content {
  border-radius: rem(30);
  box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.1);
}