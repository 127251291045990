// Desktop
.title-xl {
  @include font(800, 44, rem(59), inherit, 0, $fontSecondary);
}
.title-lg {
  @include font(700, 32, rem(48), inherit, 0);
}
.title-md {
  @include font(800, 30, rem(40), inherit, 0, $fontSecondary);
}
.title-sm {
  @include font(700, 24, rem(32), inherit, 0, $fontSecondary);
}
.title-xs {
  @include font(700, 20, rem(30), inherit, 0);
}
.text-xl {
  @include font(400, 20, rem(30), inherit, 0);
}
.text-lg {
  @include font(400, 18, rem(27), inherit, 0);
}
.text-md {
  @include font(400, 16, rem(24), inherit, 0);
}
.text-sm {
  @include font(400, 15, rem(22), inherit, 0);
}
.text-xs {
  @include font(400, 14, rem(21), inherit, 0);
}
.label-xl {
  @include font(700, 24, rem(36), inherit, 0);
}
.label-lg {
  @include font(600, 17, rem(26), inherit, 0);
}
.label-md {
  @include font(700, 16, rem(24), inherit, 0);
}
.label-sm {
  @include font(600, 14, rem(21), inherit, 0);
}
.label-xs {
  @include font(600, 11, rem(16), inherit, 0);
}
.field-text-md {
  @include font(700, 18, rem(24), inherit, 0, $fontSecondary);
}
.field-text {
  @include font(600, 16, rem(24), 0, 0);
}

// Tablet
.tablet-title-xl {
  @include font-media($media-xl, rem(32), rem(43));
}
.tablet-title-lg {
  @include font-media($media-xl, rem(24), rem(36));
}
.tablet-title-md {
  @include font-media($media-xl, rem(24), rem(32));
}
.tablet-title-sm {
  @include font-media($media-xl, rem(20), rem(24));
}
.tablet-title-xs {
  @include font-media($media-xl, rem(18), rem(27));
}
.tablet-text-xl {
  @include font-media($media-xl, rem(16), rem(24));
}
.tablet-text-lg {
  @include font-media($media-xl, rem(15), rem(22));
}
.tablet-text-md {
  @include font-media($media-xl, rem(14), rem(21));
}
.tablet-text-xs {
  @include font-media($media-xl, rem(14), rem(21));
}
.tablet-text-sm {
  @include font-media($media-xl, rem(14), rem(21));
}
.tablet-label-xl {
  @include font-media($media-xl, rem(20), rem(30));
}
.tablet-label-lg {
  @include font-media($media-xl, rem(15), rem(22));
}
.tablet-label-md {
  @include font-media($media-xl, rem(14), rem(21));
}
.tablet-label-sm {
  @include font-media($media-xl, rem(12), rem(18));
}
.tablet-label-xs {
  @include font-media($media-xl, rem(10), rem(15));
}
.tablet-field-text-md {
  @include font-media($media-xl, rem(16), rem(21));
}
.tablet-field-text {
  @include font-media($media-xl, rem(14), rem(21));
}

// Mobile
.mobile-title-xl {
  @include font-media($media-sm, rem(22), rem(29));
}
.mobile-title-lg {
  @include font-media($media-sm, rem(18), rem(27));
}
.mobile-title-md {
  @include font-media($media-sm, rem(20), rem(27));
}
.mobile-title-sm {
  @include font-media($media-sm, rem(19), rem(25));
}
.mobile-title-xs {
  @include font-media($media-sm, rem(18), rem(27));
}
.mobile-text-xl {
  @include font-media($media-sm, rem(16), rem(24));
}
.mobile-text-lg {
  @include font-media($media-sm, rem(14), rem(21));
}
.mobile-text-md {
  @include font-media($media-sm, rem(14), rem(21));
}
.mobile-text-sm {
  @include font-media($media-sm, rem(14), rem(21));
}
.mobile-text-xs {
  @include font-media($media-sm, rem(14), rem(21));
}
.mobile-label-xl {
  @include font-media($media-sm, rem(20), rem(30));
}
.mobile-label-lg {
  @include font-media($media-sm, rem(16), rem(24));
}
.mobile-label-md {
  @include font-media($media-sm, rem(14), rem(21));
}
.mobile-label-sm {
  @include font-media($media-sm, rem(12), rem(18));
}
.mobile-label-xs {
  @include font-media($media-sm, rem(10), rem(15));
}
.mobile-field-text-md {
  @include font-media($media-sm, rem(16), rem(21));
}
.mobile-field-text {
  @include font-media($media-sm, rem(14), rem(21));
}
